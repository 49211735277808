import * as React from "react";
import { Box, Button, FormField, Input, Modal, SpaceBetween } from "@amzn/awsui-components-react-v3";
import logo from '../aws_infra_icon.svg';
import { PasswordResetState } from "./PasswordResetPanel";
import { Auth } from "@awsscm/awsscm-auth-manager/auth/AuthManager";
import { NotificationBox, resetMessageStatus } from "../notification/NotificationBox";
import { AuthState, NotificationType, UserState } from "../user/UserState";


export interface UsernameValidationPanelProps {
  passwordRestState: PasswordResetState
  setPasswordRestState: (passwordRestState: PasswordResetState) => void,
  setUserState: (userState: UserState) => void,
  userState: UserState
}

/**
 * Validate user's email address in password reset flow.
 *   - If input email address exists in Argo identity pool,
 *   authentication code will be sent to the email address to complete 2nd factor authentication.
 *   - Otherwise, throw error message in notification box.
 *
 * @param props component props.
 */
export const UsernameValidationPanel = (props: UsernameValidationPanelProps) => {
  const [emailAddress, setEmailAddress] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const submitForgetPasswordRequest = (username: string) => {
    setLoading(true);
    Auth.initiatePasswordReset(username).then(() => {
      props.setUserState({
        authenticated: props.userState.authenticated, authState: props.userState.authState, user: null, message: null
      });
      props.setPasswordRestState({emailVerified: true, username: username});
      setLoading(false);
    }).catch(e => {
      setLoading(false);
      props.setUserState({
        authenticated: false,
        authState: AuthState.PASSWORD_RESET,
        user: props.userState.user,
        message: {
          message: "The provided email address is not valid, please re-try.",
          type: NotificationType.ERROR
        }
      });
    })
  };

  return (
    <Modal
      // close username validation panel will navigate back to main login page
      onDismiss={(event) => {
        if (event.detail.reason === 'closeButton') {
          props.setUserState({
            authenticated: false,
            authState: AuthState.SIGN_IN,
            user: props.userState.user,
            message: null
          })
        }
      }}
      visible={!props.passwordRestState.emailVerified}
      closeAriaLabel="Close modal"
      // headers
      header={
        <img src={logo}/>
      }
    >
      <SpaceBetween size={"m"}>
        <b> Enter an email address we can send an authorization code to in order to reset your password. </b>

        <FormField>
          <Input onChange={({detail}) => {
            resetMessageStatus({userState: props.userState, setUserState: props.setUserState});
            setEmailAddress(detail.value);
          }} value={emailAddress} onKeyDown={(event) => {
            if (event.detail.keyCode === 13) {
              submitForgetPasswordRequest(emailAddress);
            }
          }} placeholder="Email address"/>
        </FormField>

        <NotificationBox userState={props.userState} setUserState={props.setUserState}/>

        <Box textAlign={"center"}>
          <SpaceBetween size={"m"} direction={"vertical"}>
            <Button variant="primary" onClick={() => submitForgetPasswordRequest(emailAddress)}
                    loading={loading}>
              Send
            </Button>
          </SpaceBetween>
        </Box>

      </SpaceBetween>
    </Modal>
  );
}