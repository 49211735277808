import * as React from "react";
import { Auth } from '@awsscm/awsscm-auth-manager/auth/AuthManager';
import OIDC from '@awsscm/awsscm-auth-manager/auth/OIDC';
import { AuthConfig } from "@awsscm/argo-core/argo/ArgoApp.types";
import { Spinner } from "@amzn/awsui-components-react-v3";
import { getArgoConfig } from "@awsscm/argo-core/argo/window";
// @ts-ignore
import { get as _get } from 'lodash';
import { AuthState, UserState } from "../user/UserState";
import { LoginPanel } from "../login/LoginPanel";
import { AuthenticationChallengePanel } from "../login/AuthenticationChallengePanel";
import { PasswordResetPanel } from "../password/PasswordResetPanel";
import { Hub } from "aws-amplify";

export interface ArgoAuthenticatorProps {
  content: React.ReactElement
  initialState: UserState
}

/**
 * Argo authenticator that handles login authentication and password reset.
 * The self-hosted login UI is only used when the flag is enabled. Otherwise, it will just pass through.
 */
export const ArgoAuthenticator = (props: ArgoAuthenticatorProps) => {
  const config = getArgoConfig() || {};
  const authConfig = _get<AuthConfig | undefined>(config, 'argo.cognito', undefined);
  return useArgoAuthenticator(props.initialState, authConfig, props.content);
}

export function useArgoAuthenticator(initialState: UserState,
                                     authConfig: any,
                                     content: React.ReactElement) {

  const [authInitialized, setAuthInitialized] = React.useState(false);
  const [customState, setCustomState] = React.useState("/");
  // set the loading status to be true as authentication status for current user is being checked
  const [loading, setLoading] = React.useState(true);
  const [userState, setUserState] = React.useState<UserState>(initialState);

  if (authConfig) {
    OIDC.configure({
      region: authConfig.region,
      clientId: authConfig.am_clientId,
      authorityUrl: authConfig.am_identityProviderUrl,
      identityProviderUrl: authConfig.am_identityProviderUrl,
      domain: authConfig.domain,
      scope: authConfig.am_scope,
      redirectSignIn: authConfig.am_redirectSignIn,
      redirectSignOut: authConfig.am_redirectSignOut,
      responseType: authConfig.am_responseType,
      jwkEndpoint: authConfig.am_jwkEndpoint,
      authorizationEndpoint: authConfig.am_authorizationEndpoint,
      endSessionEndpoint: authConfig.am_endSessionEndpoint,
      sessionServiceEndpoint: authConfig.am_sessionServiceEndpoint,
      tokenExpirationTime: 900,
      implicitFlowRefreshEndpoint: authConfig.am_implicitFlowRefreshEndpoint,
      tokenEndpoint: authConfig.am_tokenEndpoint,
      useSelfHostedLoginUI: authConfig.am_useSelfHostedLoginUI,
      userPoolId: authConfig.userPoolId
    });
  }

  // listen to auth event dispatched by federated login to see if there is any custom state preserved
  const hubListenerCancelToken = Hub.listen('auth', ({payload: {event, data}}) => {
    switch (event) {
      case 'customOAuthState':
        setCustomState(data);
    }
  });

  React.useEffect(() => {
    // TODO this is to unblock UI integration tests, we need to have a better way to handle this
    const isCodeAuthInitialized = localStorage.getItem('oidc.mockstate') !== null;

    if (!authInitialized) {
      if (isCodeAuthInitialized) {
        setAuthInitialized(isCodeAuthInitialized);
      } else {
        Auth.isCurrentUserAuthenticated().then(authenticated => {
          if (!authenticated) {
            window.localStorage.clear();
          }
          setAuthInitialized(authenticated);
          setLoading(false);
        });
      }
    }
  });

  if (authInitialized) {
    if (customState != "/") {
      hubListenerCancelToken();
      window.location.replace(customState);
      return (<Spinner/>);
    }
    return content;
  } else if (loading) {
    return (<Spinner/>);
  } else {
    // if the user is found to be unauthorized, depending on the auth state, it will show different UI component.
    switch (userState.authState) {
      case AuthState.SIGN_IN:
        return (
          <LoginPanel
            setUserState={setUserState}
            userState={userState}
          />
        );

      case AuthState.MFA:
        return (
          <AuthenticationChallengePanel
            setUserState={setUserState}
            userState={userState}
            maxAllowedAttempts={3}
          />
        );

      case AuthState.PASSWORD_RESET:
        return (
          <PasswordResetPanel
            setUserState={setUserState}
            userState={userState}
          />
        );
    }
  }
}

