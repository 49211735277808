import * as React from "react";
import { AuthState, NotificationType, UserState } from "../user/UserState";
import { Auth } from "@awsscm/awsscm-auth-manager/auth/AuthManager";

import { Box, Button, FormField, Input, Modal, SpaceBetween } from "@amzn/awsui-components-react-v3";
import logo from "../aws_infra_icon.svg";
import { NotificationBox, resetMessageStatus } from "../notification/NotificationBox";

export enum AuthenticationChallengeType {
  MFA,
  PASSWORD_REST
}

export interface AuthenticationChallengePanelProps {
  setUserState: (userState: UserState) => void,
  userState: UserState,
  maxAllowedAttempts: number
}

/**
 * React component to handle authentication challenge for user login and password reset flow.
 * @param props component props.
 */
export const AuthenticationChallengePanel = (props: AuthenticationChallengePanelProps) => {
  // below lines are added to temporarily satisfy the line coverage stats, need to do actual implementation later
  const [authCode, setAuthCode] = React.useState<string>("");
  const [attempts, setAttempts] = React.useState<number>(1);
  const [signInLoading, setSignInLoading] = React.useState<boolean>(false);

  const submitButtonEnabled = () : boolean => {
    return authCode !== "";
  };

  const submitConfirmationCode = (confirmationCode: string) => {
    setSignInLoading(true);
      Auth.respondAuthChallenge(confirmationCode, props.userState.user)
        .then(() => props.setUserState({
          authenticated: true,
          authState: AuthState.SIGN_IN,
          user: props.userState.user,
          message: null
        }))
        .catch((e : Error) => {
          setSignInLoading(false);
          if (attempts >= props.maxAllowedAttempts || e.name === "NotAuthorizedException") {
            props.setUserState({
              authenticated: false,
              authState: AuthState.SIGN_IN,
              user: props.userState.user,
              message: {
                message: "Sign in failed because the authentication code cannot be verified or session expired.",
                type: NotificationType.ERROR
              }
            });
          } else {
            setAttempts(attempts + 1)
            props.setUserState({
              authenticated: false,
              authState: AuthState.MFA,
              user: props.userState.user,
              message: {
                message: "Incorrect authentication code, please retry.",
                type: NotificationType.ERROR
              }
            });
          }
        });
  }

  return (
    <div className={"awsscm-argo-authentication-challenge-panel"}>
      <Modal
        visible={props.userState.authState === AuthState.MFA}
        // close the modal should navigate back to main login page
        onDismiss={(event) => {
          if (event.detail.reason === 'closeButton') {
            props.setUserState({
              authenticated: false,
              authState: AuthState.SIGN_IN,
              user: props.userState.user,
              message: null
            })
          }
        }}
        closeAriaLabel="Close modal"
        // headers
        header={<div><img src={logo} className={"awsscm-infra-logo-image"}/></div>}
      >
        <SpaceBetween size={"m"} direction={"vertical"}>
          <div>
            <b> Enter the authentication code sent to {props.userState.user?.getUsername()} </b>
          </div>

          <FormField>
            <Input
              onChange={({detail}) => {
                resetMessageStatus({userState: props.userState, setUserState: props.setUserState});
                setAuthCode(detail.value);
              }}
              value={authCode}
              onKeyDown={(event) => {
                if (event.detail.keyCode === 13) {
                  submitConfirmationCode(authCode);
                }
              }}
              placeholder="Authentication Code"/>
          </FormField>

          <NotificationBox userState={props.userState} setUserState={props.setUserState}/>

          <Box textAlign={"center"}>
              <Button variant="primary" onClick={() => submitConfirmationCode(authCode)}
                      disabled={!submitButtonEnabled()}
                      loading={signInLoading}>
                Submit
              </Button>
          </Box>
        </SpaceBetween>
      </Modal>
    </div>);
}
